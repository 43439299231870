import Vue from "vue";

function getRef(orgId, caseId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("cases")
    .doc(caseId)
    .collection("riskAssessments");
}

function getRisksRef(orgId, caseId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("cases")
    .doc(caseId)
    .collection("riskAssessments")
    .doc(caseId)
    .collection("risks");
}

export async function getById(orgId, caseId) {
  return getRef(orgId, caseId)
    .doc(caseId)
    .get();
}

export async function getRiskById(orgId, caseId, id) {
  return getRisksRef(orgId, caseId)
    .doc(id)
    .get();
}

export async function list(orgId, caseId) {
  return getRef(orgId, caseId).get();
}

export async function listRisks(orgId, caseId) {
  return getRisksRef(orgId, caseId)
    .where("isDeleted", "==", false)
    .get();
}

export async function listRemovedRisks(orgId, caseId) {
  return getRisksRef(orgId, caseId)
    .where("isDeleted", "==", true)
    .get();
}
export async function removeRisk(orgId, caseId, id, user) {
  return getRisksRef(orgId, caseId)
    .doc(id)
    .update({ isDeleted: true, deletedBy: user, deletedOn: new Date() });
}

export async function unRemoveRisk(orgId, caseId, id) {
  return getRisksRef(orgId, caseId)
    .doc(id)
    .update({ isDeleted: false, deletedBy: null, deletedOn: null });
}
export async function save(orgId, caseId, payload) {
  const obj = Object.assign({}, payload);
  if (payload.id != undefined) {
    return getRef(orgId, caseId)
      .doc(payload.id)
      .update(obj);
  } else {
    return getRef(orgId, caseId)
      .doc(caseId) //set the id to the case id as they should be one to one
      .set(obj);
  }
}

export async function saveRisk(orgId, caseId, payload) {
  const obj = Object.assign({}, payload);
  if (payload.id != undefined) {
    return getRisksRef(orgId, caseId)
      .doc(payload.id)
      .update(obj);
  } else {
    return getRisksRef(orgId, caseId).add(obj);
  }
}
