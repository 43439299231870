<template>
  <v-container fluid class="pt-0 risk-review-form">
    <v-dialog v-model="dialog" width="600">
      <v-form ref="form" @submit.prevent="validateBeforeSubmit">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>Monitor and review controls</v-card-title>

          <v-card-text>
            <v-col cols="12" class="py-0 mt-3">
              <v-checkbox
                class="mt-0"
                v-model="vm.sufficientAtMinimisingRisk"
                :label="`Are the planned control measures sufficient and effective in minimising the level of risk for the participant`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-checkbox
                class="mt-0"
                v-model="vm.haveChangesbeenPlanned"
                :label="` Have there been any changes to the planned control measures?`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-checkbox
                class="mt-0"
                v-model="vm.haveChangesMadeToRa"
                :label="` Have these changes been made to the RA`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-checkbox
                class="mt-0"
                v-model="vm.furtherControlMeasuresRequired"
                :label="`Are further control measures required in the future`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-textarea
                filled
                v-model="vm.details"
                label="Details"
                rows="1"
                data-vv-as="details"
                name="details"
                :error-messages="errors.collect('details')"
              ></v-textarea>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close()">Cancel</v-btn>
            <v-btn color="primary" @click="review()">Mark as Reviewed</v-btn>
            <v-btn color="primary" @click="approve()">Approve</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col class="mt-0 pt-0 pb-0" cols="12">
        <v-card>
          <v-card-actions>
            <v-icon @click="back">mdi-arrow-left-circle</v-icon>

            <v-spacer></v-spacer>
            <v-btn @click="dialog=true">Monitor and review controls</v-btn>
            <v-btn color="primary" type="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" class="mt-2 pt-0">
        <v-card>
          <v-card-text>
            <v-row>
           <v-col cols="12">
            <v-select
              item-text="name"
              v-model="supportCategory"
              :items="lineItemDefinition.supportCategories"
              label="Support Category"
              return-object
            ></v-select>
          </v-col>
              <v-col class="xs py-0" sm="6" cols="12" md="6" lg="6">
                <strong>Reviewed by:</strong>
                <span
                  v-if="vm.reviewedBy!=undefined && vm.reviewedBy!==null"
                >&nbsp;{{vm.reviewedBy.email}}</span>
              </v-col>
              <v-col class="xs py-0" sm="6" cols="12" md="6" lg="6">
                <strong>Reviewed date:</strong>
                <span
                  v-if="vm.reviewedDate!=undefined && vm.reviewedDate!==null  "
                >&nbsp;{{moment( util.toDt(vm.reviewedDate)).format("DD MMM YYYY")}}</span>
              </v-col>
              <v-col class="xs py-0" sm="6" cols="12" md="6" lg="6">
                <strong>Approved date:</strong>
                <span
                  v-if="vm.approvedDate!=undefined && vm.approvedDate!==null  "
                >&nbsp;{{moment( util.toDt(vm.approvedDate)).format("DD MMM YYYY")}}</span>
              </v-col>
              <v-col class="xs py-0" sm="6" cols="12" md="6" lg="6">
                <strong>Status:</strong>
                <span v-if="vm.status!=undefined && vm.status!==null">&nbsp;{{vm.status}}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <v-card-actions>
            Risks
            <v-spacer></v-spacer>
            <v-select
              v-model="deleted"
              :items="[{text: 'Active risks', value: false}, {text: 'Deleted risks', value: true}]"
              @change="toggleDeleted"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="addRisk()">Add Risk</v-btn>
          </v-card-actions>
          <v-card-text>
            <v-data-table
              id="list-table"
              ref="datatable"
              :headers="headers"
              :items="risks"
              item-key="index"
              class="mr-2"
              v-if="!deleted"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td>{{ props.item.area.title}} - {{ props.item.subArea}}</td>
                  <td>
                    <v-chip
                      small
                      :color="props.item.assessedRiskLevel.color"
                      :text-color="props.item.assessedRiskLevel.textColor"
                    >{{props.item.assessedRiskLevel.level}}</v-chip>
                  </td>
                  <td>{{props.item.modifiedBy==undefined ?"":props.item.modifiedBy.displayName}}</td>
                  <td>{{props.item.modifiedOn==undefined ?"":moment(props.item.modifiedOn.toDate()).format("YYYY-MM-DD hh:mm")}}</td>

                  <td class="justify-center layout px-0">
                    <v-icon small class="mr-2" @click="editRisk(props.item)">mdi-pencil</v-icon>
                    <v-icon small @click="deleteItem(props.item)">mdi-delete</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>

            <v-data-table
              id="list-table"
              ref="deletedDatatable"
              :headers="deletedHeaders"
              :items="deletedRisks"
              item-key="index"
              class="mr-2"
              v-if="deleted"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td>{{ props.item.area.title}} - {{ props.item.subArea}}</td>
                  <td>
                    <v-chip
                      small
                      :color="props.item.assessedRiskLevel.color"
                      :text-color="props.item.assessedRiskLevel.textColor"
                    >{{props.item.assessedRiskLevel.level}}</v-chip>
                  </td>

                  <td>{{moment( util.toDt(props.item.deletedOn)).format("YYYY-MM-DD hh:mm")}}</td>

                  <td>{{props.item.deletedBy==undefined ?"":props.item.deletedBy.displayName}}</td>
                  <td class="justify-center layout px-0">
                    <v-icon small @click="unDeleteItem(props.item)">mdi-undo-variant</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-form>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as riskAssessmentService from "../../../services/individualRiskAssessmentService";
//import * as refDataService from "../../../services/referenceDataService";
import * as lineItemService from "../../../services/lineItemSevice";
import moment from "moment";
Vue.use(VeeValidate);

export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "participant-details",
  data: () => ({
    locked: false,
    util: null,
    deleted: false,
    e1: 0,
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    vm: {},
    risks: [],
    deletedRisks: [],
    form: {},
    valid: true,
    dialog: false,
    programs: [],
    lineItemDefinition: {},
    checkbox: false,
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    headers: [
      { text: "Area", value: "area" },

      { text: "Assessed risk level", value: "assessedRiskLevel" },
      { text: "Last modified by", value: "modifiedBy" },
      { text: "Modified on", value: "modifiedOn" },
      { text: "", value: "actions" }
    ],
    deletedHeaders: [
      { text: "Area", value: "area" },

      { text: "Assessed risk level", value: "assessedRiskLevel" },

      { text: "Deleted On", value: "deletedOn" },
      { text: "Deleted By", value: "deletedBy" },
      { text: "", value: "actions" }
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.util = this.$utils;
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    user() {
      return this.$store.getters.user;
    },
    currentAssessment() {
      return this.$store.getters.currentAssessment;
    }
  },

  methods: {
    icon() {
      return this.locked ? "mdi-lock-open" : "mdi-lock";
    },
    init() {
      let me = this;
      me.vm = {};
      riskAssessmentService
        .getById(this.currentOrganisation.id, this.currentCase.id)
        .then(result => {
          if (result.exists) {
            me.vm = result.data();
            me.$store.commit("setCurrentAssessment", null);
          }
        });

      riskAssessmentService
        .listRisks(this.currentOrganisation.id, this.currentCase.id)
        .then(result => {
          let risks = [];
          result.docs.map(doc => {
            const item = doc.data();
            item.id = doc.id;
            risks.push(item);
          });
          me.risks = risks;
        });
      /*
      refDataService
        .listByKey(me.currentOrganisation.id, "programs")
        .then(results => {
          results.docs.map(doc => {
            let item = doc.data();
            item.id = doc.id;
            me.programs.push(item);
          });
        });
        */
      this.lineItemDefinition = {};
      lineItemService
        .list()
        .then(result => {
          result.docs.map(doc => {
            const lid = doc.data();
            const dt = new Date();
            const from = new Date(lid.effectiveFromDate.replace(/-/g, "/"));
            const to = new Date(lid.effectiveToDate.replace(/-/g, "/"));
            if(dt>=from && dt<=to){
              this.lineItemDefinition = lid;
              this.lineItemDefinition.id = doc.id;
              console.log(this.lineItemDefinition.supportCategories);
            }
          });
        });
    },
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },
    save() {
      let me = this;
      riskAssessmentService
        .save(me.currentOrganisation.id, me.currentCase.id, me.vm)
        .then(ref => {
          if (ref != undefined) {
            me.vm.id = ref.id;
          }
          me.$store.dispatch("setToast", {
            message: "Individual risk assessment saved",
            color: "success"
          });
          me.$store.commit("setCurrentAssessment", me.vm);
       //  close();
        });
    },

    deleteItem(item) {
      const me = this;
      const index = me.risks.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        riskAssessmentService
          .removeRisk(
            me.currentOrganisation.id,
            me.currentCase.id,
            item.id,
            me.user
          )
          .then(result => {
            console.log(result);
            me.risks.splice(index, 1);
          });
      }
    },
    toggleDeleted() {
      //this.deleted = !this.deleted;
      if (this.deleted) {
        this.getDeleted();
      }
    },
    getDeleted() {
      riskAssessmentService
        .listRemovedRisks(this.currentOrganisation.id, this.currentCase.id)
        .then(result => {
          let risks = [];
          result.docs.map(doc => {
            const risk = doc.data();
            risk.id = doc.id;
            risks.push(risk);
          });

          this.deletedRisks = risks.sort((ax, bx) => {
            if (ax.modifiedOn == undefined) {
              return 1;
            }
            if (bx.modifiedOn == undefined) {
              return -1;
            }
            let a = ax.modifiedOn.toDate();
            let b = bx.modifiedOn.toDate();
            return a > b ? -1 : a < b ? 1 : 0;
          });
        });
    },
    unDeleteItem(item) {
      const me = this;
      const index = me.deletedRisks.indexOf(item);

      riskAssessmentService
        .unRemoveRisk(me.currentOrganisation.id, me.currentCase.id, item.id)
        .then(result => {
          console.log(result);
          me.risks.push(item);
          me.deletedRisks.splice(index, 1);
        });
    },
    review() {
      this.vm.status = "Reviewed";
      this.vm.reviewedDate = new Date();
      this.vm.reviewedBy = this.user;
      this.save();
      this.close();
    },
    approve() {
      this.vm.status = "Approved";
      this.vm.approvedDate = new Date();
      this.vm.approvedBy = this.user;
      this.save();
      this.close();
    },
    back() {
      setTimeout(() => {
        this.dialog = false;
        this.$router.push("/participant-management/current-participant");
      }, 300);
    },
    close() {
      setTimeout(() => {
        this.dialog = false;
      }, 300);
    },
    addRisk() {
      this.save();
      this.$store.commit("setCurrentRisk", null);
      this.$router.push("/individual-risk-assessments/assessment");
    },
    editRisk(item) {
      this.$store.commit("setCurrentRisk", JSON.parse(JSON.stringify(item)));
      this.$router.push("/individual-risk-assessments/assessment");
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.save();

          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.risk-review-form .v-label {
  font-size: 0.9em;
}
</style>

